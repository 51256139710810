/**
 * Provide localization with key replacement. Inspired by Svelte repl, but
 * adapted so that each component contains its own translations. This mostly
 * makes sense since we only need two (nb and nn), and thus it's easier to have
 * the relevant texts directly in the components that use them
 *
 * @see https://svelte.dev/repl/de39de663ef2445b8fe17b79c500013b?version=3.55.1
 */
import {getWithCredentials} from '~/common/fetchHelpers.js';

function replaceVars(text, vars) {
  try {
    Object.entries(vars).forEach(([k, v]) => {
      const regex = new RegExp(`{{${k}}}`, "g");
      text = text.replace(regex, v);
    });
  } catch (e) {
    console.error(e);
  }
  return text;
}

/**
 * @param {Record<string, Record<string, string>>} translations
 * @param {string?} locale
 * @returns {function(string, Record<string, string>?): string}
 */
export default function localizer(translations, locale) {
  const l = locale || globalThis.vesta?.locale || 'nb-NO';
  return (key, vars = {}) => {
    const t = translations[l] || translations['nb-NO'];
    if (!t) {
      console.error(`No valid translations found for ${l} or nb-NO`)
      return key;
    }
    return replaceVars(t[key] || translations['nb-NO'][key], vars) || key;
  }
}

export async function fetchTranslation(key) {
  const locale = globalThis.vesta?.locale || 'nb';
  return getWithCredentials(`/aid/translate?key=${encodeURIComponent((key || '').trim())}&locale=${locale}`)
    .then(r => {
      if(!r.ok) {
        console.warn('Error fetching translation for key', key);
        return key;
      }
      return r.json();
    })
    .then(j => {
      return j?.translation || key
    })
    .catch(e => {
      console.error('Failed to fetch key', key, e)
      return key;
    });
}

import i18next from "i18next";
import { createI18nStore } from "svelte-i18next";

i18next.init({
  lng: globalThis.vesta?.locale || 'nb-NO',
  fallbackLng: 'no',
  resources: {
    no: {
      translation: {
        'login-error.genericTitle': "Feil under pålogging",
        'login-error.genericMessage': "Vi kunne ikke fullføre innlogging. Det er trygt å forsøke igjen.",
        'login-error.reason': "Feilkode: {{reason}}",
        'login-error.cookies-disabled-on-site': "For å kunne logge på {{siteName}} må du tillate cookies for {{siteDomain}}.",
      }
    },
    'nn-NO': {
      translation: {
        'login-error.genericTitle': "Feil under innlogging",
        'login-error.genericMessage': "Vi kunne ikkje fullføre innlogginga. Du kan trygt prøve igjen.",
        'login-error.reason': "Feilkode: {{reason}}",
        'login-error.cookies-disabled-on-site': "For å kunne logge inn på {{siteName}} må du tillate informasjonskapslar for {{siteDomain}}.",
      }
    },
    'da-DK': {
      translation: {
        'login-error.genericTitle': "Fejl under login",
        'login-error.genericMessage': "Vi kunne ikke fuldføre login. Du kan roligt prøve igen.",
        'login-error.reason': "Fejlkode: {{reason}}",
        'login-error.cookies-disabled-on-site': "For at kunne logge på {{siteName}} skal du tillade cookies for {{siteDomain}}.",
      }
    }
  },
  interpolation: {
    escapeValue: false, // not needed for svelte as it escapes by default
  }
});

export const i18n = createI18nStore(i18next);
